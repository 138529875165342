import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Home } from '../Pages/Home'
import { AboutUs } from '../Pages/AboutUs'
import { Header } from '../Components/Header'
import { Footer } from '../Components/Footer'
import { useLocation } from 'react-router-dom'
import { Faqs } from '../Pages/Faqs'
import { Tokenomics } from '../Pages/Tokenomics'
import { Roadmap } from '../Pages/Roadmap'


// For header class control for home page
const PageWrapper = ({ children }) => {
    const location = useLocation(); 
    const isHomePage = location.pathname === '/';
  
    return (
      <div className={isHomePage ? 'banner-section-outer position-relative home-page-background' : 'sub-banner-section-outer position-relative home-page-background'}>
        {children}
      </div>
    );
};


export const AllRoutes = () => {

  return (
    <div>
        <PageWrapper>
            <Header/>
                <Routes>
                    <Route index element={<Home/>}/>
                    <Route path='about-us' element={<AboutUs/>}/>
                    <Route path='frequently-asked-questions' element={<Faqs/>}/>
                    <Route path='tokenomics' element={<Tokenomics/>}/> 
                    <Route path='roadmap' element={<Roadmap/>}/> 
                </Routes>
            <Footer/>
        </PageWrapper>
    </div>
  )
}
