import React from 'react'
import { RoadmapMain } from '../Components/Roadmap/RoadmapMain'

export const Roadmap = () => {
  return (
    <div>
        <RoadmapMain/>
    </div>
  )
}
