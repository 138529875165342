import React from 'react'
import star1 from "../images/star1.png";
import star2 from "../images/star3.png";

export const Faqs = () => {
  return (
    <div>
    <section className='feature_section position-relative faq-mar-top-bot'>
        <div className="accordian-section zindex-1">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="heading position-relative text-center">
                            <h6>FAQ’s</h6>
                            <h2>Frequently Asked Questions</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-2" data-aos="fade-up"></div>
                    <div className="col-lg-8 col-md-12 col-sm-8" data-aos="fade-up">
                        <div className="about-service-section-text accordian-section-inner-text position-relative">
                            <div className="accordian-inner">
                                <div id="accordion1">
                                    <div className="accordion-card">
                                        <div className="card-header" id="headingFour">
                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                How does the auto-buy/sell feature work?
                                            </button>
                                        </div>
                                        <div id="collapseFour" className="collapse" aria-labelledby="headingFour">
                                        <div className="card-body">
                                            <p className="text-size-16 text-left mb-0 p-0">Our platform scans influencer calls and executes trades based on criteria you set, such as contract verification and liquidity status. KolsAI keeps you informed while automating the details.</p>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-card">
                                        <div className="card-header" id="headingFive">
                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                How do influencers get paid?
                                            </button>
                                        </div>
                                        <div id="collapseFive" className="collapse" aria-labelledby="headingFive">
                                        <div className="card-body">
                                            <p className="text-size-16 text-left mb-0 p-0">Influencers earn a share of fees from trades executed on their calls, rewarding them for impactful insights that drive successful trades.</p>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-card">
                                        <div className="card-header" id="headingSix">
                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                What makes the token launch fair?
                                            </button>
                                        </div>
                                        <div id="collapseSix" className="collapse" aria-labelledby="headingSix">
                                        <div className="card-body">
                                            <p className="text-size-16 text-left mb-0 p-0">Ownership is assigned to a null address at launch, ensuring decentralized access with no individual control post-launch.</p>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="accordion-card">
                                        <div className="card-header" id="headingseven">
                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                What can the KolsAI chatbot help me with?
                                            </button>
                                        </div>
                                        <div id="collapseSeven" className="collapse" aria-labelledby="headingseven">
                                        <div className="card-body">
                                            <p className="text-size-16 text-left mb-0 p-0">The chatbot provides on-demand insights, answering questions on trending tokens, platform tools, and trading strategies, so you’re never left without answers in a fast-paced market.</p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
        <figure className="banner_top_shape mb-0 position-absolute top_bottom_shape">
        <img
          src={star1}
          alt=""
          className="img-fluid w-50"
          style={{ opacity: 0.2 }}
        />
      </figure>
      <figure
        className="banner_right_shape mb-0 position-absolute"
        style={{ width: "24%" }}
      >
        <img
          src={star2}
          alt=""
          className="img-fluid"
          style={{ opacity: 0.2 }}
        />
      </figure>
    </div>
  )
}
