import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import logo from '../images/KolsAI-Logo.png'

export const Header = () => {
  return (
    <div>
    <header>
        <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light">
                <Link className="navbar-brand" to="/"><figure className="mb-0"><img src={logo} alt="" className="img-fluid" width="70%"/></figure></Link>
                <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <NavLink className="nav-link" activeclassname="active" to="/">Home</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" activeclassname="active" to="about-us">About us</NavLink>
                        </li>
                        
                        <li className="nav-item">
                            <NavLink className="nav-link" activeclassname="active" to="roadmap">Roadmap</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" activeclassname="active" to="frequently-asked-questions">FAQ's</NavLink>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://docs.kolsai.io/" target="_blank" rel="noopener noreferrer">Whitepaper</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </header>

    {/* </div> */}
    </div>
  )
}
