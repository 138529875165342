import React from 'react'
import Imgaes1 from "../images/robot4.png"
import star1 from "../images/star1.png";
import star2 from "../images/star3.png";

export const AboutUs = () => {
  return (
    <div>
      <section className="feature_section position-relative pb-50">
        <div className="container">
            <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div className="feature_content" data-aos="fade-right">
                        {/* <h6>About</h6> */}
                        <h2>Redefining <span className="special-color">Crypto Insights</span> with Real-Time, AI-Powered Intelligence</h2>
                        <p>At KolsAI, we believe in empowering traders and investors with the right information at the right time. We are an advanced, AI-driven platform that monitors and analyzes messages from top Key Opinion Leaders (KOLs) across crypto communities. By processing over 10,000 messages daily and tracking 2000+ tokens launched across major blockchains, we deliver insights that cut through the noise and help you make smarter, faster decisions.</p>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div className="feature_image">
                        <figure className="feature_main_image position-relative mb-0" style={{right:"30%"}}>
                            <img src={Imgaes1} alt="" className="" width="140%"/>
                        </figure>
                    </div>
                </div>
            </div>
        </div>
        </section>


        <section className="aboutus_section position-relative pt-0 pb-50">
        <div className="container unlock-real-time-box">
          <div className="row align-items-center">
            
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="aboutus_content" data-aos="fade-right">
                {/* <h6 className='text-center-mobile'>Overview of the Platform</h6> */}
                <h2 className="text-center-mobile unlock-heading">
                  Our Mission
                </h2>
                <p>To revolutionize crypto trading by offering transparent, curated insights based on real-time data. We’re dedicated to making the crypto space accessible, actionable, and safer for everyone.</p>
                <h2 className="text-center-mobile unlock-heading">
                How We Work
                </h2>
                <p>Using state-of-the-art AI algorithms, KolsAI curates the most trending tokens and presale opportunities shared by influencers on Ethereum, Solana, Polygon, Sui, TON, and Tron networks. Our team of developers and blockchain enthusiasts has built a platform that’s intuitive yet powerful, designed for both beginners and experienced traders.</p>
                <h2 className="text-center-mobile unlock-heading">
                Our Values
                </h2>
                <p>Transparency, reliability, and empowerment are at the core of KolsAI. We work tirelessly to ensure that our data is up-to-date, our analytics are precise, and our insights are valuable, empowering users to navigate the fast-moving world of crypto with confidence.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
        <figure className="banner_top_shape mb-0 position-absolute top_bottom_shape">
        <img
          src={star1}
          alt=""
          className="img-fluid w-50"
          style={{ opacity: 0.2 }}
        />
      </figure>
      <figure
        className="banner_right_shape mb-0 position-absolute"
        style={{ width: "24%" }}
      >
        <img
          src={star2}
          alt=""
          className="img-fluid"
          style={{ opacity: 0.2 }}
        />
      </figure>
    </div>
  )
}
