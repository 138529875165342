import React from 'react'
import Tokonmics1 from '../images/tokonoimcs-1.png'
import Tokonmics2 from '../images/tokonomics-2.png'

export const Tokenomics = () => {
  return (
    <div>
        <section className="feature_section position-relative">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div className="feature_image">
                        <figure className="feature_main_image position-relative mb-0">
                            <img src={Tokonmics1} alt="" className="img-fluid"/>
                        </figure>
                        <figure className="feature_second_image position-absolute mb-0">
                            <img src={Tokonmics2} alt="" className="img-fluid"/>
                        </figure>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div className="feature_content" data-aos="fade-right">
                        <h6></h6>
                        <h2>Tokenomics </h2>
                        <p>Our token operates with a 5%/5% tax structure. 5% goes to platform sustainability, ensuring continuous innovation and growth, while 5% supports token holders with rewards and benefits.
                        This layout will provide clarity and highlight the unique selling points of your platform, helping you attract buyers and users for both the token and the platform.</p>
                    </div>
                </div>
            </div>
            <figure className="feature_background_shape mb-0 position-absolute left_right_shape">
                <img src="assets/images/features_background_shape.jpg" alt=""/>
            </figure>
            <figure className="feature_top_shape mb-0 position-absolute top_bottom_shape">
                <img src="assets/images/features_top_shape.png" alt="" className="img-fluid"/>
            </figure>
        </div>
        </section>
        <figure className="banner_top_shape mb-0 position-absolute top_bottom_shape">
            <img src="assets/images/sub_banner_top_shape.png" alt="" className="img-fluid"/>
        </figure>
        <figure className="banner_background_shape mb-0 position-absolute left_right_shape">
            <img src="assets/images/sub_banner_background_shape.png" alt=""/>
        </figure>
        
    </div>
  )
}
