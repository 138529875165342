import React from 'react'
import logo from '../images/KolsAI-Logo.png'
import Twitter from '../images/twitterx.png'
import Telegram from '../images/telegram-logo-white.png'
import Dextool from '../images/dextools-logo.png'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';

export const Footer = () => {
    const location = useLocation();

    const isRoadmapPage = location.pathname === '/roadmap' || location.pathname === '/frequently-asked-questions';

  return (
    <div>
        <section id="footer_section" className={isRoadmapPage ? 'footer-section roadmap-footer' : 'footer-section'}>
          <div className="container">
              <div className="middle-portion">
                  <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="about_col">
                              {/* <a href="#"> */}
                                  <figure className='text-center'>
                                      <img src={logo} alt="" className="img-fluid" width="300px"/>
                                      {/* <div className='footer-logo-text'>Kols<span className='text-white'>-Ai</span></div> */}
                                  </figure>
                              {/* </a> */}
                              <ul className="list-unstyled mb-0">
                                  <li>
                                      <p className="text-size-16"><b>Disclaimer :</b> "The information on this website, including articles, insights, viewpoints, and everything else, is intended to be general knowledge only. Don't take it as specific advice for your finances, investments, or trades. We highly recommend doing your own research and talking to a qualified professional before making any financial decisions."</p>
                                  </li>
                                  
                              </ul>
                          </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-sm-left text-center d-lg-block">
                      <hr/>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className="d-flex list-unstyled mb-0">
                                        <div>
                                            <span></span>
                                            <Link className='footer-tags' to="/">Home</Link>
                                        </div>
                                        <div>
                                            <span></span>
                                            <Link className='footer-tags' to="about-us">About Us</Link>
                                        </div>
                                        <div>
                                            <span></span>
                                            <Link className='footer-tags' to="roadmap">Roadmap</Link>
                                        </div>
                                        <div>
                                            <span></span>
                                            <Link className='footer-tags' to="frequently-asked-questions">FAQ's</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                        <div className="footer-info-content text-right">
                                        {/* <p></p> */}
                                        <div className="about_col">
                                            <ul className="list-unstyled mb-0 margin-top-20-mobile">
                                                <li className="icons hover-effect"><a href="https://x.com/kolsai_io" target='_blank'><img src={Twitter} alt="" className="img-fluid" height="30px" width="30px"/></a></li>
                                                <li className="icons hover-effect"><a href="https://t.me/Kolsai_io" target='_blank'><img src={Telegram} alt="" className="img-fluid ml-3 mr-3" height="30px" width="30px"/></a></li>
                                                <li className="icons hover-effect"><a href="https://www.dextools.io/" target='_blank'><img src={Dextool} alt="" className="img-fluid" height="30px" width="30px"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="bottom-portion">
              <div className="copyright col-xl-12"> 
                  <p>Copyright © 2024 KolsAI All rights reserved.</p>
              </div>
          </div>
        </section>
    </div>
  )
}
